import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Bandas } from 'app/games/bandas/Bandas';
import { Facebook } from 'app/games/facebook/Facebook';

import { VContent } from 'components';

// import { Session } from '../session/Session';
// import { HContent, VContainer, VContent, CContainer, Button } from 'components';
// import { Menu } from './Menu';
// import { CheckoutHistory } from './checkout-history/CheckoutHistory';

const Market = () => {
  const [item, set_item] = useState(null);
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);

  useEffect(() => {
    set_item(area);
  }, [area]);

  const render_markets = () => (
    <Switch>
      <Route exact path={`${path}/championship`}>
        <Bandas area={area} />
      </Route>
      <Route exact path={`${path}/book`}>
        <Facebook area={area} />
      </Route>
    </Switch>
  );

  return (
    <Screen background={BACKGROUND}>
      {/* <IconToolbarContainer> */}
      {/* <Session /> */}
      {/* <Menu /> */}
      {/* </IconToolbarContainer> */}
      <VContent>
        {/* {area && render_markets()} */}
        {render_markets()}
      </VContent>
    </Screen>
  );
};

export { Market };
