import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// import { Flex } from 'components/grids/v2';
import { Image } from 'components/images/v3';
import { ListItem } from './ListItem';
import { generator, orientation } from './setting';

const GItems = ({ set_query, events, schools }) => {
  const history = useHistory();
  const [page, set_page] = useState(null);
  const [item, set_item] = useState(null);

  const [champion, set_champion] = useState(null);

  const build_chain = () => {
    const base = JSON.parse(JSON.stringify(generator));
    base.location = {
      top: 10,
      left: 10,
      width: 80,
      height: 80,
    };

    return base;
  };

  const on_select = (data) => {
    const school = schools.shift();

    if (!school) {
      set_champion(data.data);
      return;
    }

    if (orientation.L === data.orientation) {
      item.right.data = school;
      set_item({ ...item });
    }
    if (orientation.R === data.orientation) {
      item.left.data = school;
      set_item({ ...item });
    }
  };

  const on_select_event = (data) => {
    // console.log(data);
    set_query(data);
  };

  const on_select_clean = () => {
    // base.data = null;
    set_champion(null);
  };

  const on_home = () => {
    history.push('/');
  };

  const on_schools = () => {
    history.push('/schools');
  };

  useEffect(() => {
    const base = build_chain();
    base.left.data = schools.shift();
    base.right.data = schools.shift();
    set_item(base);
  }, [schools]);

  const render_links = (i) => (
    <div onClick={() => on_select_event(i)} className="links">
      {i.name}
    </div>
  );

  const render_item = () => (
    <ListItem item={item} page={page} on_select={on_select} />
  );

  const render_champion = () => (
    <div className="tor-item-champion">
      <div className="champeon">
        <div className="image-title" onClick={on_select_clean}>
          CAMPEÓN
        </div>
        <div className="image-cup" />
        <div className="image-school">
          <Image image={champion.image_id} direction={Image.V} width="100%" height="100%" quality={1080} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="tor-back">
      <div className="tor-item-links">
        <div className="links">
          <div onClick={on_home}>principal</div>
        </div>
        <div className="links">
          <div onClick={on_schools}>my colegio</div>
        </div>
        <h5>
          EVENTOS
        </h5>
        {
          events.map(render_links)
        }
      </div>
      {item != null && render_item(item)}
      { champion && render_champion() }
    </div>
  );
};

GItems.propTypes = {
  set_query: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  schools: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { GItems };
