import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/images/v3';

const ListItem = ({ item, on_select, page }) => {
  const build_position = (data) => ({
    top: data.top === undefined ? 'auto' : `${data.top}%`,
    left: data.left === undefined ? 'auto' : `${data.left}%`,
    right: data.right === undefined ? 'auto' : `${data.right}%`,
    bottom: data.bottom === undefined ? 'auto' : `${data.bottom}%`,
    width: `${data.width}%`,
    height: `${data.height}%`,
  });

  const render_school = (data) => (
    <div className="school">
      <div className="image">
        <Image image={data.image_id} direction={Image.H} width="100%" height="100%" quality={320} />
      </div>
      <div className="title">
        <div className="title-item">
          {data.name}
        </div>
      </div>
    </div>
  );

  const render_left = (data) => {
    const on_click_left = (e) => {
      e.stopPropagation();
      on_select(data);
    };

    return (
      <div className="tor-box-item" style={build_position(data.location)} onClick={on_click_left}>
        {data.data && render_school(data.data)}
      </div>
    );
  };

  const render_right = (data) => {
    const on_click_right = (e) => {
      e.stopPropagation();
      on_select(data);
    };

    return (
      <div className="tor-box-item" style={build_position(data.location)} onClick={on_click_right}>
        {data.data && render_school(data.data)}
      </div>
    );
  };

  const render_line_h = (data) => {
    if (!data.parent) {
      return <></>;
    }

    const size = ((data.parent.location.width / 2) * page.width) / 100;
    const style = {};
    if (data.orientation === 'L') {
      style.top = '50%';
      style.width = `${size}px`;
      style.right = `-${size}px`;
    }
    if (data.orientation === 'R') {
      style.top = '50%';
      style.width = `${size}px`;
      style.left = `-${size}px`;
    }

    return (
      <div className="box-line" style={style} />
    );
  };

  const render_line_v = (data) => {
    const renderer = [];

    if (data.left.child) {
      const size = ((data.location.top - data.left.child.location.top
        - (data.left.child.location.height / 2)) * page.height) / 100;
      const style = {};
      style.top = '0%';
      style.right = '50%';
      style.height = `${size}px`;
      style.top = `-${size}px`;

      renderer.push(<div className="box-line-v" style={style} />);
    }
    if (data.right.child) {
      const size = ((data.location.top - data.left.child.location.top
        - (data.left.child.location.height / 2)) * page.height) / 100;
      const style = {};
      style.bottom = '0%';
      style.right = '50%';
      style.height = `${size}px`;
      style.bottom = `-${size}px`;

      renderer.push(<div className="box-line-v" style={style} />);
    }

    return (
      <>
        {renderer.map((rend) => rend)}
      </>
    );
  };

  return (
    <div className="tor-item" style={build_position(item.location)}>
      <div className="tor-box">
        {render_left(item.left)}
        {render_right(item.right)}
        {render_line_h(item)}
        <div className="vs">
          VS
        </div>
        {render_line_v(item)}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
