import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { api, toast } from 'generics/services/globals/v3';
import { useHistory } from 'react-router-dom';
import { CardList } from './card1/CardList';

const GItems = ({ set_total }) => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const on_select = useCallback((item) => {
    // history.push(`${route.list}/${item._id}`);
  }, [history]);

  const convert = (item) => ({
    image_id: item.is_approve ? item.image_id : null || null,
    band_id: item.is_approve ? item.band_id : null || null,
    code: item.code || 'Not found',
    name: item.name || 'Not found',
    quantity: item.quantity || 'Not found',
    city: item.city || 'Not found',
    anniversary: item.anniversary || 'Not found',
    description: item.description || 'Not found',
  });

  useEffect(() => {
    api.get('/v1/schools', null, {})
    .then(({ data }) => {
      data.sort((a, b) => {
        const a_quant = a.quantity ? a.quantity : 0;
        const b_quant = b.quantity ? b.quantity : 0;
        return b_quant - a_quant;
      });
      set_total({
        bands: data.length,
        quantity: data.reduce((n, { quantity }) => n + (quantity || 0), 0),
      });
      set_items([...data]);
    })
    .catch(toast.api_danger);
  }, []);

  return (
    <CardList items={items} convert={convert} on_select={on_select} />
  );
};

GItems.propTypes = {
  set_total: PropTypes.any.isRequired,
};

export { GItems };
