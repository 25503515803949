import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';

import { Dimmer } from 'components/elements/v1';
import { Account } from 'app/session/Account';

import { Home } from './app/home/Home';
import { NotFound } from './app/not-found/NotFound';
import { Login } from './app/login/Login';
// import { Register } from './app/register/Register';
import { Sales } from './app/sales/Sales';
import { Market } from './app/market/Market';

import { GRoute as SchoolsRoute } from './app/schools/GRoute';

import './App.css';

const App = () => {
  const { loading } = useSelector((state) => state.loading);

  return (
    <BrowserRouter>
      <Account />
      <ReactNotifications />
      <Dimmer active={loading}>
        <Switch>
          <Route path="/schools">
            <SchoolsRoute />
          </Route>
          <Route exact path="/register">
            {/* <Register /> */}
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/games">
            <Market />
          </Route>
          <Route path="/sales">
            <Sales />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Dimmer>
    </BrowserRouter>
  );
};

export default App;
