import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import { HToolbarButton, HToolbarTitle, HToolbarFlex } from '../container/HToolbarContainer';

const HeaderLogout = () => {
  const history = useHistory();

  const on_home = () => {
    history.push('/');
  };

  const on_contest = () => {
    history.push('/games/championship');
  };

  const on_facebook = () => {
    history.push('/games/book');
  };

  const on_schools = () => {
    history.push('/schools');
  };

  const on_register = () => {
    history.push('/register');
  };

  const on_register_schools = () => {
    history.push('/schools/new');
  };

  const on_login = () => {
    history.push('/login');
  };

  return (
    <>
      <HToolbarTitle>
        <div aria-hidden="true" onClick={on_home}>
          Un Boliviano
        </div>
      </HToolbarTitle>
      <HToolbarTitle>
        <small aria-hidden="true" onClick={on_contest}>
          Campeonato
        </small>
      </HToolbarTitle>
      <HToolbarTitle>
        <small aria-hidden="true" onClick={on_facebook}>
          ¿Quien es mejor?
        </small>
      </HToolbarTitle>
      <HToolbarTitle>
        <small aria-hidden="true" onClick={on_schools}>
          Colegios
        </small>
      </HToolbarTitle>
      <HToolbarTitle>
        <small aria-hidden="true" onClick={on_register_schools}>
          Registrar Mi Colegio
        </small>
      </HToolbarTitle>
      <HToolbarFlex />
      <HToolbarButton>
        {/* <Button label="Uneté" color="teal" onClick={on_register} /> */}
        <Button label="Iniciar session" onClick={on_login} />
      </HToolbarButton>
    </>
  );
};

export { HeaderLogout };
