import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GENERIC, BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Flex } from 'components/grids/v3';
import { CContainer, Button } from 'components';
import { Header as Header2 } from 'components/titles/v2';
import { Header } from '../header/Header';
import { GItems } from './GItems';

import { DateSelector } from './DateSelector';

import './Home.scss';

const Home = () => {
  const history = useHistory();
  const { account } = useSelector((state) => state.auth);
  const [total, set_total] = useState({
    bandas: 0,
    integrantes: 0,
  });

  const handle_select = () => {
    history.push(GENERIC.APP_BASE_URL);
  };

  const on_search = useCallback(() => {
    // api.bookings.report({ area_id: area._id, ...range })
    // .then(({ data }) => {
    //   set_items(data);
    // })
    // .catch(toast.api_danger);
  }, []);

  const on_download = () => {
  };

  return (
    <Screen background={BACKGROUND}>
      <Flex direction={Flex.V}>
        <Flex.Scrap>
          <Header />
          <div className="home-title app-bg">
            {
              account && (
                <div>
                  <CContainer>
                    <Button label={GENERIC.APP_FULL_NAME} icon="checkmark" onClick={handle_select} />
                  </CContainer>
                </div>
              )
            }
          </div>
          <div className="home-title">
            <h1>{GENERIC.APP_FULL_NAME}</h1>
            <p>
              Bienvenido a un producto de UnBoliviano un espacio dedicado a las
              Bandas Estudiantiles de Bolivia.
              <br />
              La comunidad estudiantil actualmente cuenta con
              {` ${total.bands} `}
              bandas y
              {` ${total.quantity} `}
              integrantes a lo largos de todo el territorio boliviano.
            </p>
          </div>
          {/* <Header2 title="Lista de registros en el hotel" /> */}
          {/* <DateSelector on_search={on_search} on_download={on_download} /> */}
          <GItems set_total={set_total} />
        </Flex.Scrap>
      </Flex>
    </Screen>
  );
};

export { Home };
