import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v2';
import { toast } from 'generics/services/globals/v4';
import services from 'generics/api/toks';

import { GItems } from './GItems';
import { shuffle } from './shuffle';

import './blitch.scss';

const Facebook = ({ area }) => {
  const [events, set_events] = useState([]);
  const [query, set_query] = useState(null);
  const [schools, set_schools] = useState([]);

  useEffect(() => {
    if (!query) {
      return;
    }

    services.schools.find({
      categories: query._id,
    })
    .then(({ data: items }) => {
      set_schools(shuffle(items));
    })
    .catch(toast.api_danger);
  }, [query]);

  useEffect(() => {
    services.events.find({})
    .then(({ data }) => {
      set_events(data);
      const event = data.find((item) => item.name === 'Todos');

      if (!event) {
        throw new Error('No event were found');
      }

      set_query(event);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <Flex.Item>
        <div className="tournament-book">
          <GItems area={area} events={events} set_query={set_query} schools={schools} />
        </div>
      </Flex.Item>
    </Flex>
  );
};

Facebook.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { Facebook };
